<template>
    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
<!--        <left-bar-comp>-->
<!--            <sports-left-bar-comp></sports-left-bar-comp>-->
<!--        </left-bar-comp>-->
        <div class="content_section">
            <div class="content">
                <sub-title> 답변확인</sub-title>
                <div class="board_list">
                    <div class="board_detail">

                        <div class="bd_titles">
                            <span class="bd_title">{{item.content}}</span>
                            <span class="bd_time">{{item.createTime|datef('MM/DD')}}</span>
                        </div>
                        <div class="bd_board_content" v-if="item.contentReply" v-html="item.contentReply">
                        </div>
                        <div class="bd_board_content notreplay" v-if="!item.contentReply">
                            답변중 입니다.
                        </div>
                        <div class="go_back">
                            <button class="" @click="goback()">뒤로가기</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- right 메뉴 -->
<!--        <right-bar-comp>     -->
<!--            <user-info-comp slot="userinfo"></user-info-comp>-->
<!--            <right-buttons-comp slot="btns"></right-buttons-comp>      -->
<!--            <sports-bet-cart-comp slot="betcart"></sports-bet-cart-comp>-->
<!--            <right-bar-banner-comp slot="banner"></right-bar-banner-comp>-->
<!--        </right-bar-comp>-->

        <foot-comp></foot-comp>
    </div>
</template>

<script>
    import SubTitle from "../../components/SubTitle";
    import RightBarComp from "../../components/RightBarComp";
    import {getBoardDetail, getCustomerCenterDetail, saveBoardComment} from "../../network/userRequest";
    import sportsConst from "../../common/sportsConst";
    import SportsBetListComp from "../../components/sports/SportsBetListComp";
    import LeftBarComp from "../../components/LeftBarComp";
    import LeisureLeftComp from "../../components/leisuregame/LeisureLeftComp";
    import TopbarComp from "../../components/TopbarComp";
    import FootComp from "../../components/FootComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import CommentsComp from "../../components/CommentsComp";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import LeisureBetListComp from "../../components/leisuregame/LeisureBetListComp";
    import UserInfoComp from "../../components/UserInfoComp";
    import RightButtonsComp from "../../components/RightButtonsComp";
    import SportsBetCartComp from "../../components/sports/SportsBetCartComp";

    export default {
        name: "CustomerCenterDetail",
        components: {
            SportsBetCartComp,
            RightButtonsComp,
            UserInfoComp,
            LeisureBetListComp,
            SportsLeftBarComp,
            CommentsComp,
            RightBarBannerComp,
            FootComp, TopbarComp, LeisureLeftComp, LeftBarComp, SportsBetListComp, RightBarComp, SubTitle
        },
        data() {
            return {
                sportsConst,
                item:{},
                cusId:0,
            }
        },
        methods: {
            getCustomerCenterDetail() {
                getCustomerCenterDetail(this.cusId).then(res => {
                    this.item = {}
                    if (res.data.success) {
                        this.item = res.data.data
                        console.log(this.item)
                    } else {
                        this.goback()
                    }
                })
            },
            goback() {
                this.$router.go(-1)
            },
        },
        created() {
            this.cusId = this.$route.params.id
            this.getCustomerCenterDetail()
        }
    }
</script>

<style scoped>
    @import url("../../assets/css/base.css");
    @import url("../../assets/css/common.css");
    @import url("../../assets/css/style.css");
    @import url("../../assets/css/media.css");
    @import url("../../assets/css/sports.css");
    .bd_titles {
        border: 1px solid #4b4b4b;
        color: #fff9ff;
        box-sizing: border-box;
        padding-left: 5px;
    }

    .bd_titles span {
        overflow: hidden;
        display: inline-block;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 12px !important;
    }

    .bd_titles .bd_title {
        width: 75%;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
        color: #aaa9aa;
    }

    .bd_titles .bd_time {
        width: 25%;
        text-align: right;
        box-sizing: border-box;
        padding-right: 5px;

    }

    .bd_note .bd_title {
        color: #f19f09;
        font-weight: bold;
        text-align: center;
    }

    .bd_board_content {
        box-sizing: border-box;
        padding: 20px 20px;
        color: #b8b8b8;
        background-color: #151515;
        border: 1px solid #4b4b4b;
        border-top: transparent;
    }

    .go_back{
        width: 100%;
        text-align: center;
    }
    .go_back button {
        width: 120px;
        font-size: 12px !important;
        margin: 10px 0;
        padding: 10px;
        background-color: #8e8e8e;
        color: #ffffff;
    }

    .comments {
        box-sizing: border-box;
        padding: 5px 5px;
    }

    .comments .comment_input {
        border: 1px solid #f19f09;
        background-color: #e0e0e0;
        width: 100%;
        height: 30px;
        color: #1c1c25;
        outline: none;
    }

    .comments lable {
        line-height: 30px;
    }

    .comments button {
        width: 100%;
        margin-top: 10px;
    }

    .comments span {
        line-height: 30px;
        border-bottom: 1px solid #2e313a;
        display: inline-block;
    }

    .comments .nickname {
        width: 20% !important;
        margin-left: 6px;
    }

    .comments .con {
        width: 50% !important;
        line-height: 30px;
        text-align: center;
    }

    .comments .time {
        width: 19%;
        text-align: right;
        margin-right: 6px;
    }
</style>